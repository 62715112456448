
import { defineComponent, onMounted, reactive, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Users from "@/views/still/admin/users.vue"
import Actors from "@/views/still/admin/actors.vue"
import { ElNotification } from 'element-plus'

export default defineComponent({
  name: "customer-details",
  components: {
    Users,
    Actors,
  },
 
  setup() {

    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      lRoles: [] as any,
      drawer: false,
      drawer_morale: false,
      count: 0,
      paneBord : 1,
      zUser: {} as any,
      reloadlst : 1,
    });

    const modalActeur= reactive({
      act_n_seq: 0,
      act_c_raison_sociale: '',
      act_ark_n_type: '',
      act_c_categorie: '',
      act_c_status: '',
      act_c_mail: '',
      act_c_phone: '',
      act_c_adr: '',
      act_d_import: '',
      act_ark_c_nom2: '',
      act_ark_c_nom3: '',	
      act_ark_c_nom4: '',
      act_knum_responsable : '',
    });

    const modalUser= reactive({
      uti_n_seq: 0,
      uti_c_service: "",
      uti_c_phone: '',
      uti_c_mail: '',
      uti_knum_role: '',
      uti_c_prenom: '',
      uti_c_nom : '',
      uti_c_mess_instant : '',
      uti_c_status: '',
      producteur: [],
    });

    onMounted(async () => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Administration de l'application", []);

      let myListLiv = await getAxios("/getSVProducteur");
      state.lProducteurs = myListLiv.results;
      
      myListLiv = await getAxios("/getSVRoles");
      state.lRoles = myListLiv.results;

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      state.lUsers.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",").map(function(e){return parseInt(e,10)});
        }
      });

      // console.log(state.lUsers);
      
      state.count =state.count + 1;
    });



    const newUser = () => {
      modalUser.uti_n_seq = 0;
      modalUser.uti_c_service = "",
      modalUser.uti_c_phone = '',
      modalUser.uti_c_mail = '',
      modalUser.uti_knum_role = '',
      modalUser.uti_c_prenom = '',
      modalUser.uti_c_nom = '',
      modalUser.uti_c_mess_instant = '',
      modalUser.uti_c_status = '',
      state.drawer = true;
    }

    const editUser = (d) => {
      modalUser.uti_n_seq = d.uti_n_seq;
      modalUser.uti_c_service = d.uti_c_service,
      modalUser.uti_c_phone = d.uti_c_phone,
      modalUser.uti_c_mail = d.uti_c_mail,
      modalUser.uti_knum_role = d.uti_knum_role,
      modalUser.uti_c_prenom = d.uti_c_prenom,
      modalUser.uti_c_nom = d.uti_c_nom,
      modalUser.uti_c_mess_instant = d.uti_c_mess_instant,
      modalUser.uti_c_status = d.uti_c_status,
      modalUser.producteur = d.producteur,
      state.drawer = true;
    }

    const getLengthProd = computed(() => {
      if (modalUser && modalUser.producteur) {
        return modalUser.producteur.length;
      }
      return 0
    });


    const addEditUser = () => {
        // console.log("modalUser", modalUser);

        if (!modalUser.uti_c_prenom) {
          ElNotification({ title: 'Erreur', message: 'Le champ Prénom est obligatoire', type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_nom) {
          ElNotification({ title: 'Erreur', message: 'Le champ Nnom est obligatoire', type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_mail) {
          ElNotification({ title: 'Erreur', message: 'Le champ Email est obligatoire', type: 'error', }); 
          return false;
        }

        mAxiosApi
          .post("/addEditUser", modalUser)
          .then( async() => {
            const mNotif = modalUser.uti_n_seq > 0 ? "Utilisateur modifié avec succès !" : "Utilisateur créé avec succès !";
            ElNotification({ title: 'Succès', message: mNotif, type: 'success', })
            state.count = state.count + 1
            state.drawer = false;
          })

    }
  
    const editPersMorale = (d) => {
      modalActeur.act_n_seq = d.act_n_seq;
      modalActeur.act_c_raison_sociale = d.act_c_raison_sociale;
      modalActeur.act_ark_n_type = d.act_ark_n_type;
      modalActeur.act_c_categorie = d.act_c_categorie;
      modalActeur.act_c_mail = d.act_c_mail;
      modalActeur.act_c_phone = d.act_c_phone;
      modalActeur.act_c_adr = d.act_c_adr;
      modalActeur.act_ark_c_nom2 = d.act_ark_c_nom2;
      modalActeur.act_ark_c_nom3 = d.act_ark_c_nom3;
      modalActeur.act_ark_c_nom4 = d.act_ark_c_nom4;
      modalActeur.act_knum_responsable = d.act_knum_responsable;

      state.drawer_morale = true;
    }

    const newPersMorale = () => {
      modalActeur.act_n_seq = 0;
      modalActeur.act_c_raison_sociale = "";
      modalActeur.act_ark_n_type = "";
      modalActeur.act_c_categorie = "";
      modalActeur.act_c_mail = "";
      modalActeur.act_c_phone = "";
      modalActeur.act_c_adr = "";
      modalActeur.act_ark_c_nom2 = "";
      modalActeur.act_ark_c_nom3 = "";
      modalActeur.act_ark_c_nom4 = "";
      modalActeur.act_knum_responsable = "";
      state.drawer_morale = true;
    }

    const addEditPersMorale = async () => {
      
      mAxiosApi
        .post("/addEditPersMorale", modalActeur)
        .then( async() => {
          const mNotif = modalActeur.act_n_seq > 0 ? "Acteur modifié avec succès !" : "Acteur créé avec succès !";
          ElNotification({ title: 'Succès', message: mNotif, type: 'success', })
          state.count = state.count + 1
          state.drawer_morale = false;
        })
    };

    return {
      state,
      modalActeur,
      editPersMorale,
      newPersMorale,
      addEditPersMorale,
      addEditUser,
      modalUser,
      newUser,
      editUser,    
      getLengthProd,  
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

